import { Directive, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainService } from '../public-api';

@Directive({
  selector: '[appCountryFlag]'
})
export class CountryFlagDirective {
  @Input() countryId!: string;
  @HostBinding('class') flagClass: string | undefined;

  private subscription: Subscription = new Subscription();
  public countryList: any[] = [];

  constructor(private mainService: MainService) { this.getCountryList(); }

  ngOnInit() { this.flagClass = 'me-1 fi fi-' + this.getCountrySymbol(this.countryId); }

  getCountryList() {
      this.subscription.add(this.mainService.httpGetCountry().subscribe((res:any) => {
          this.countryList = res.data && res.data.length ? res : [];
      }));
  }

  getCountrySymbol(countryId: string) {
      return this.countryList.find((ele) => ele.id === countryId)?.symbol?.toLowerCase()
  }

}
