<div *ngIf="control && control?.errors && (control?.dirty || control?.touched)" class="danger" id="error-message">
  <div *ngIf="control?.errors?.required || control?.errors?.noWhiteSpaceValidator">
    {{ controlName }}
    {{ 'is required' }}
  </div>
  <div *ngIf="control.errors.maxlength">
    {{ controlName }}
    {{
    'should only contain ' +
    control.errors.maxlength['requiredLength'] +
    ' characters'
    }}
  </div>
  <div *ngIf="control.errors.minlength">
    {{ controlName }}
    {{
    'should contain minimum ' +
    control.errors.minlength['requiredLength'] +
    ' characters'
    }}
  </div>
  <div *ngIf="control?.errors?.pattern">
    {{ controlName }}
    {{ ' is invalid' }}
  </div>
  <div *ngIf="
      control?.errors?.hasCapitalCase ||
      control?.errors?.hasSmallCase ||
      control?.errors?.hasSpecialCharacters ||
      control?.errors?.hasNumber
    ">
    {{ controlName }}
    {{
    control?.errors?.hasCapitalCase
    ? ' has atleast one upper case letter'
    : control?.errors?.hasSmallCase
    ? ' has atleast one small case letter'
    : control?.errors?.hasSpecialCharacters
    ? ' has atleast one special character'
    : control?.errors?.hasNumber
    ? ' has atleast one numeric character'
    : ' is invalid'
    }}
  </div>
  <div *ngIf="control?.errors?.min || control?.errors?.max">
    {{controlName}}
     should be min 1 and max {{maxNumber?maxNumber:''}}</div>
  <div *ngIf="control?.errors?.editor25000MaxChar">should only contain 100000 characters</div>
  <div *ngIf="control?.errors?.editor1000MaxChar">should only contain 1000 characters</div>
  <div *ngIf="control?.errors?. editor250MaxChar">
    Message should only contain 250 characters</div>
</div>