<table border="0" cellpadding="0" cellspacing="0" width="100%">
  <!-- LOGO -->
  <tr>
    <td bgcolor="#FFD300" align="center">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
        <tr>
          <td align="center" valign="top" style="padding: 40px 10px 40px 10px;"> </td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td bgcolor="#FFD300" align="center" style="padding: 0px 10px 0px 10px;">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
        <tr>
          <td bgcolor="#ffffff" align="center" valign="top"
            style="padding: 40px 20px 20px 20px; border-radius: 4px 4px 0px 0px; color: #111111; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 48px; font-weight: 400; letter-spacing: 4px; line-height: 48px;">
            <h1 style="font-size: 48px; font-weight: 400; margin: 2;">Welcome!</h1>
            <img src="assets/images/logo.svg" width="120" height="60" style="display: block; border: 0px;" />
          </td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td bgcolor="#f4f4f4" align="center" style="padding: 0px 10px 0px 10px;">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
        <tr>
          <td bgcolor="#ffffff" align="left"
            style="padding: 20px 30px 40px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
            <p style="margin: 0;">We're excited to have you get started. First, you need to confirm your email address
              <b>{{this.email ? this.email : ''}}</b>. Just
              press the button below.</p>
          </td>
        </tr>
        <tr>
          <td bgcolor="#ffffff" align="left">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td bgcolor="#ffffff" align="center" style="padding: 20px 30px 60px 30px;">
                  <table border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td align="center" style="border-radius: 3px;" bgcolor="#FFD300">
                        <button class="d-flex align-items-center btn btn-small btn-primary  rounded-pill"
                          (click)="verifyAccount()">
                          <i class="fas fa-check-circle me-2 fs-5"></i>
                          Confirm Account
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr> <!-- COPY -->
      </table>
    </td>
  </tr>
  <tr>
    <td bgcolor="#f4f4f4" align="center" style="padding: 30px 10px 0px 10px;">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
        <tr>
          <td bgcolor="#FFECD1" align="center"
            style="padding: 30px 30px 30px 30px; border-radius: 4px 4px 4px 4px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
            <h2 style="font-size: 20px; font-weight: 400; color: #111111; margin: 0;">Need more help?</h2>
            <p style="margin: 0;"><a href="#" target="_blank" style="color: #FFD300;">We&rsquo;re here to help you
                out</a></p>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>