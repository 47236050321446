import { AbstractControl, FormGroup } from '@angular/forms';

export interface CountrySchema {
  country_code?: string;
  country_name?: string;
  alpha_code_3?: string;
  iso_code?: string;
  is_deleted?: boolean;
  created_by?: string;
  updated_by?: string;
  deleted_by?: string;
  created_at?: string;
  updated_at?: string;
  deletedAt?: string;
}

export interface CountryApiSchema {
  data: {
    data: Array<CountrySchema>;
  };
}
export interface ProfileDetailSchema {
  detail: any;
  count?: any;
}

export interface UserDetailSchema {
  id: string;
  email: string;
  contact: string;
  assignedRole: {
    name: string;
    id: string;
  };
  profile: {
    id: string;
    name: string;
    user_id: string;
    dob: string;
    address: Array<Address>;
  };
}

export interface Address {
  city: string;
  id: string;
  address: string;
  zip_code: string;
}

export interface ProfileFormSchema {
  email?: string;
  contact?: string;
  name?: string;
  city?: string;
  password?: string;
}

export interface ProfileForm extends FormGroup {
  value: ProfileFormSchema;
  controls: {
    email?: AbstractControl;
    contact?: AbstractControl;
    name?: AbstractControl;
    city?: AbstractControl;
    password?: AbstractControl;
  };
}

export interface PasswordSchema {
  password: string;
  changePassword: string;
}

export interface PasswordFormSchema {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class Password {
  oldPassword!: string;
  newPassword!: string;
  confirmPassword!: string;
}

export interface PaginationSchema {
  showBoundaryLinks: boolean;
  limit: Number;
  maxSize: Number;
  totalItems: Number;
  firstText: string;
  lastText: string;
  prevText: string;
  nextText: string;
  page: Number;
}
