import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { EventBusService, TokenStorageService } from './core/services';
import { Subscription } from 'rxjs';
import { Events } from './core/enums';
import { Router } from '@angular/router';

import * as firebase from 'firebase/analytics';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../environment/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'rhp';
  public isLoaderVisible: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private eventBusService: EventBusService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService
  ) {}

  ngAfterViewInit() {
    const app = initializeApp(environment.env.firebaseConfig);
    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(app);
    console.log('analytics ===[]===>',analytics);
    logEvent(analytics, 'app-init', {
      page_title: window.document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
  }

  ngOnInit(): void {
    this.subscription.add(
      this.eventBusService.on(Events.LOADING_START, () => {
        this.loaderValue = true;
      })
    );

    this.subscription.add(
      this.eventBusService.on(Events.LOADING_END, () => {
        this.loaderValue = false;
      })
    );

    this.subscription.add(
      this.eventBusService.on(Events.SIGNOUT, () => {
        this.signOut();
      })
    );
  }

  private set loaderValue(event: boolean) {
    this.isLoaderVisible = event || false;
    this.cdr.detectChanges();
  }

  public signOut() {
    this.tokenStorageService.clearTokenSession();
    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
