export type ModalFlag = 'VIEW' | 'EDIT' | 'DELETE' | 'ADD';

export type ModalData = {
  title: string;
  description?: string;
  data?: any;
};

export interface ModalSchema {
  flag: ModalFlag;
  data?: ModalData;
}
