<!-- <p>report-reason works!</p> -->

<!-- Modal -->
<div class="modal-content border-0">
  <div class="modal-header justify-content-center">
    <h2 class="fs-4">Report Reason</h2>
    <button type="button" (click)="closemodal()" class="btn-close"></button>
  </div>
  <div class="modal-body border-0 py-3 px-4">
    <div class="col-12 row">
      <div class="col-3"><span class="fw-normal">Reason :</span></div>
      <div class="col-9"><span class="text-break">{{ contentMessage?.complaint_categories?.complaint_category || 'No reason added'}}</span></div>
    </div>
    <div class="col-12 row">
      <div class="col-3"><span class="fw-normal">Message :</span></div>
      <div class="col-9"><span class="text-break">{{ contentMessage?.complaint_description || 'No message added'}}</span></div>
    </div>
  </div>
</div>