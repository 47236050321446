<!-- if user has no permission -->
<main class="main-wrapper text-center">
  <div class="container">
      <h3 class="text-center m-0 ">{{'global.no_permissions' | translate}}</h3>
      <p class="para-text text-center pt-3 pb-2">{{'global.or' | translate}}</p>
      <a class="btn btn-primary" (click)="logout()">
          <span class="px-3">
              <img style="margin-top: -1px;" src="assets/images/logout.png" alt="Log out" class="me-2">
              {{'global.diff_login_creds' | translate}}
          </span>
      </a>
      <a (click)="support()"
          class="link primary-color d-block text-decoration-none fw-600 text-center mt-3">
          {{'global.contact_support' | translate}}
      </a>
  </div>
</main>
