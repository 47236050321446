import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { MainService } from '../../public-api';
import { PERMISSION_KEYS } from 'src/app/core/enums';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  public permission_keys = PERMISSION_KEYS;
  showManageUsersItems: boolean = false;
  showManageRide: boolean = false;
  showDropItems: boolean = false;
  showDropItems1: boolean = false;
  showReportItems: boolean = false;
  showPriceItems: boolean = false;
  showInfoItems: boolean = false;
  showDocumentItems: boolean = false;
  showManageCountry: boolean = false;

  currentOpenItem = '';

  constructor(private router: Router, public commonService: CommonService, public mainService: MainService) { }

  checkActiverRoute(urlPath: string) {
    if (this.router.url.includes(urlPath)) { return true; } else { return false }
  }

  showSidebarDrop() { this.showDropItems = !this.showDropItems; }
  showSidebarUsersDrop() { this.showManageUsersItems = !this.showManageUsersItems; }
  showSidebarRideDrop() { this.showManageRide = !this.showManageRide; }
  showSidebarReportDrop() { this.showReportItems = !this.showReportItems; }
  showSidebarPriceDrop() { this.showPriceItems = !this.showPriceItems; }
  showInforDrop() { this.showInfoItems = !this.showInfoItems; }
  showSidebarDrop1() { this.showDropItems1 = !this.showDropItems1; }
  showSidebarDocument() { this.showDocumentItems = !this.showDocumentItems; }
  showSidebarCountry() { this.showManageCountry = !this.showManageCountry }
  setCurrentOpenItem(event: any) { this.currentOpenItem = event; }
}
